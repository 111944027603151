
  import { Component, Vue } from 'vue-property-decorator';
  import axios from 'axios';
  import ArticleList from '@/components/articles/ArticleList.component.vue';
  import { Article, ArticleImageDto } from '@/interfaces/arcticles/Article.entity';
  import { AuthenticationService } from '@/services/authentication.service';
  import { Utils } from '@/scripts/Utils';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import * as Consts from '@/scripts/Consts';
  import { Rubric } from '@/interfaces/Rubric.interface';
import CustomImage from '@/components/CustomImage.component.vue';


/**
 * @desc page d'affichage de tous les articles non archivés et validés
 */
  @Component({
    components: {
      'article-list' : ArticleList,
      "c-img" : CustomImage
    },
  })
  export default class RubricArticles extends Vue {
    allArticles : Array<Article> = [];
    stopLoading= false;
    loadArticlesCount = 80;

    currentRubricId = 0;
    currentRubric : Rubric = new Rubric();
    errorMsg = '';
  
    mounted()  : void {
      this.getCurrentRubricId();
      this.loadNextArticles(new Date());
    }

    /**
     * @desc récupère l'id de la rubrique actuelle
     */
    getCurrentRubricId() : void {
      this.currentRubricId = parseInt(this.$route.params.rubricId);
      this.loadCurrentRubric(this.currentRubricId);
    }

    /**
     * @desc charge la rubrique à afficher
     */
    loadCurrentRubric(rubricId : number) : void {
      if(!this.currentRubricId) return;
      const headers = AuthenticationService.getRequestHeader();

      axios
        .get(UrlConsts.getRubric + "/" + rubricId)
        .then((response) => {
          this.currentRubric = new Rubric(response.data);
        })
        .catch(error => console.log(error))
    }

    /**
     * @desc charge les articles limités au nombre "loadArticlesCount" plus anciens que la date "lastDate"
     */
    loadNextArticles(lastDate : Date) : void {
      if(!this.currentRubricId) return;
      if(this.stopLoading) return;

      const headers = AuthenticationService.getRequestHeader();
      const query = '?' + Utils.createUrlQuery({id: this.currentRubricId, limit: this.loadArticlesCount, startDate: lastDate})

      this.stopLoading = true;
      axios
        .get(  UrlConsts.getAllRubricArticlesLimited + query, headers)
        .then((response) => {
          const responseArray = response.data;
          if(!responseArray || !responseArray.length || responseArray.length < this.loadArticlesCount) {
            this.stopLoading = true;
          }
          else {
            setTimeout(() => {
              this.stopLoading = false;
            }, 50);
          }
          this.allArticles = this.allArticles.filter((x: Article) => !responseArray.find((y : any) => y.id === x.id));
          this.allArticles = this.allArticles.concat(responseArray.map((x: any) => new Article(x)));
          if(this.allArticles.length === 0) {
            this.errorMsg = 'Aucun article n\'a encore été publié';
          }
          else {
            this.errorMsg = '';
          }
      
        })
        .catch(error => console.log(error))
    }

    /**
     * @desc renvoie un string contenant le d&tail de la rubrique (type, archivation?)
     */
    private getRubricDetails(rubric : Rubric) : string {
      let result = "";
      switch(rubric.type)
      {
        case Consts.rubric_type_schoolProject:
          result = "projet d'école";
          if(this.isArchived(rubric.archivedDate))
            result += " archivé";
          return result;
        
        case Consts.rubric_type_classroom:
          result = "classe";
          if(this.isArchived(rubric.archivedDate))
            result += " archivée";
          return result;
      }
      return result;
    }

    /**
     * @desc renvoie le type de la rubrique en chaine de caractère
     */
    private getRubricTypeStr(type : number) : string {
      switch(type)
      {
        case Consts.rubric_type_schoolProject:
          return "Articles du projet d'école";
        
        case Consts.rubric_type_classroom:
          return "Articles de la classe";
      }

      return "";
    }

    /**
     * @desc vérifie si  la date est déjà passée
     */
    private isArchived(archivedDate : Date | string) : boolean {
      archivedDate = new Date(archivedDate);
      return archivedDate < new Date();
    }

    /**
     * @desc renvoie le nom complet de la rubrique
     */
    private getRubricFullName(rubric : Rubric) : string {
      return Utils.getRubricFullName(rubric.startDate, rubric.endDate, rubric.name);
    }
  }
